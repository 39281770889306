<template>
  <NotulenForm mode="Tambah" module="Notulen Rakom, Rawas, RUPS"> </NotulenForm>
</template>

<script>
import NotulenForm from './form';

const NotulenAdd = {
  name: 'NotulenAdd',
  components: { NotulenForm },
};

export default NotulenAdd;
</script>
